import React, { Component } from 'react';
import { Link } from "gatsby"
import Image from "../components/image"
import "./hamburgers.css"

class Menu extends Component {
    constructor(props) {
      super(props);
      this.state = { active: false, isTop: true };
    }
    handleClick = () => {
      this.setState({ active: !this.state.active });
    };
    componentDidMount() {
        document.addEventListener('scroll', () => {
        const isTop = window.scrollY < 150;
        if (isTop !== this.state.isTop) {
            this.setState({ isTop })
        }
    });
    }
    render() {
        return (
            <div className={"navbar-container"+(this.state.isTop ? "" : " show")}>
                <div id="logo">
                <Link to="/">
                    <Image filename="logo-rond-petit.png" alt="" />
                </Link>
                </div>
                <button className={"hamburger hamburger--spring" + (this.state.active ? " is-active" : "")} onClick={this.handleClick} type="button">
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
                <nav role="navigation" className={"menu" + (this.state.active ? " is-active" : "")}> 
                    <div className="menu-container">
                        <Link to="/">Accueil</Link> <br />
                        <Link to="/la-compagnie/">La compagnie</Link> <br />
                        <Link to="/les-creations/">Les créations</Link> <br />
                        <Link to="/les-cours/">Les cours</Link> <br />
                        <Link to="/nous-soutenir/">Nous soutenir</Link> <br />
                        <a href="mailto:cieailesdecire@gmail.com" >Contact</a>
                    </div>
                </nav>
            </div>
        )
    }
}

export default Menu;  
