/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
//import "./layout.css"
import "bootstrap/dist/css/bootstrap.css"
import "./style.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main>{children}</main>
        <footer style={{
          marginTop: `2rem`,
        }}>
          <div className="upper-footer row">
            <div className="col-md-6 col-sm-6 mb-3">
              <Link to="/mentions-legales/">Mentions légales</Link> - <a href="mailto:cieailesdecire@gmail.com">Contact</a>
            </div>
            <div className="col-md-6 col-sm-6 row" style={{
              justifyContent: `center`,
              }}>
              <a href="https://www.facebook.com/cieailesdecire/" target="_blank" rel="noreferrer"><i className="facebook" title="Notre page Facebook"></i></a> <a href="https://www.instagram.com/ailesdecire/" target="_blank" rel="noreferrer"><i className="instagram" title="Notre page Instagram"></i></a>
            </div>
          </div>
          <div className="bottom-footer">
            <p>
              <strong>© Cie Ailes de Cire | {new Date().getFullYear()}</strong>
            </p>
          </div>
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
